export enum TextFeedbackPermission {
  TEMPLATE_CREATE = 'text.feedback.create.text.feedback.templates',
  TEMPLATE_COPY = 'text.feedback.copy.text.feedback.templates',
  TEMPLATE_VIEW = 'text.feedback.list.and.search.text.feedback.templates',
  TEMPLATE_EDIT = 'text.feedback.edit.text.feedback.templates',
  TEMPLATE_DELETE = 'text.feedback.delete.text.feedback.templates',
  // TODO: Add exact names from api
  INSTANCE_VIEW = 'text.feedback.list.and.search.text.feedback',
  INSTANCE_EDIT = 'text.feedback.edit.text.feedback',
  INSTANCE_DELETE = 'text.feedback.delete.text.feedback',
  INSTANCE_DOWNLOAD = 'text.feedback.download.text.feedback',
}
