export const NoteArrowLeft = `<svg xmlns="http://www.w3.org/2000/svg" width="44" height="51" viewBox="0 0 44 51" fill="none">
  <path d="M41.5937 32.5502V3.26892C41.5937 2.71664 41.146 2.26892 40.5937 2.26892H6.96875C6.41646 2.26892 5.96875 2.71664 5.96875 3.26892V47.5814C5.96875 48.1337 6.41647 48.5814 6.96875 48.5814H16.6562" stroke="currentColor" stroke-width="4"/>
  <path d="M0.625 12.9564H11.3125" stroke="currentColor" stroke-width="4"/>
  <path d="M18.4375 12.9564H32.6875" stroke="currentColor" stroke-width="4"/>
  <path d="M0.625 25.4252H11.3125" stroke="currentColor" stroke-width="4"/>
  <path d="M18.4375 25.4252H32.6875" stroke="currentColor" stroke-width="4"/>
  <path d="M43.375 41.4564L23.7812 41.4564" stroke="currentColor" stroke-width="4"/>
  <path d="M0.625 37.8939H11.3125" stroke="currentColor" stroke-width="4"/>
  <path d="M30.3844 33.8861L22.7307 41.5773L30.3809 49.2128" stroke="currentColor" stroke-width="4"/>
</svg>`;
