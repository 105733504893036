import { CustomRolePermission } from '@core/enums/permission/custom-roles-permission.enum';
import { PartnerPermission } from '@core/enums/permission/partner-permission.enum';
import { SystemRolePermission } from '@core/enums/permission/system-roles-permission.enum';
import { UserPermission } from '@core/enums/permission/user-permission.enum';
import { WorkInProgressPermission } from '@core/enums/permission/wip-permission.enum';
import { CardTemplatePermission, TicketTemplatePermission } from '@interticket/template-layout-editor-angular/permissions';
import { AreaOfInterestPermission, ArticleSettingsPermission, BlockchainPermission, CardPermission, ComfortCostPermission, ContentManagementPermission, ContentTranslationPermission, CustomFieldsPermission, DataPumpPipelinePermission, EmailComponentsPermission, EmailTemplatesPermission, EmailTypesPermission, EventPermission, HtmlBuilderPermission, InvoicePermission, NotificationsPermission, OrganizerPermission, OTRSManagementPermission, PartnerPageSettingsPermission, PartnersApiManagementPermission, PaymentMethodsPermission, PaymentTransactionPermission, PlaceCategoriesPermissions, PlacePermission, RatePermission, RefundPermission, RegionsPermission, ReportingPermission, SurveyPermission, TextFeedbackPermission, TransactionPermission, TranslationsManagementPermission } from '@services/permissions';
import { BoSalesPermission } from 'app/modules/boxoffice/modules/sales/permissions/bo-sales-permission.enum';
import { DashboardPermission } from 'app/modules/dashboard/permissions/dashboard-permission.enum';
import { CategoryPermission } from 'app/modules/event/modules/categories/permissions/category-permission.enum';
import { DiscountPermission } from 'app/modules/event/modules/discount/permissions/discount-permission.enum';
import { AttachmentsPermission } from 'app/modules/partner-settings/modules/attachments/permissions/attachments-permissions.enum';
import { ReportPermission } from 'app/modules/report/modules/reports/permissions/report-permission.enum';
import { ReportCategoryPermissions } from 'app/modules/settings/report-category/permissions/report-category-permission.enum';
import { BankCardPermission } from 'app/modules/smart-city/bank-card/permissions/bank-card-permission.enum';
import { BannerPermission } from 'app/modules/smart-city/banner/permissions/banner-permission.enum';
import { SmartCityCardPermission } from 'app/modules/smart-city/card/permissions/card-permission.enum';
import { GovernmentPermission } from 'app/modules/smart-city/government/permissions/government-permission.enum';
import { MarketPermission } from 'app/modules/smart-city/market/permissions/market-permission.enum';
import { ParkingPermission } from 'app/modules/smart-city/parking/permissions/parking-permission.enum';
import { PlacePermission as SmartCityPlacePermission } from 'app/modules/smart-city/place/permissions/place-permission.enum';
import { ProductPermission } from 'app/modules/smart-city/product/permissions/product-permission.enum';
import { SmartCityReportPermission } from 'app/modules/smart-city/reports/permissions/report-permission.enum';
import { StepToStopPermission } from 'app/modules/smart-city/step-to-stop/permissions/step-to-stop.permission';
import { VignettePermission } from 'app/modules/smart-city/vignette/permissions/vignette-permission.enum';
import { AdmissionPermission } from 'app/modules/tools/admission/enums/admission-permission.enum';
import { AccountPermission } from 'app/modules/user-account/permissions/account-permission.enum';
import { ContractsPermission } from 'app/modules/user-management/modules/contracts/permissions/contracts-permission.enum';
import { BookingServiceCategoryPermissions } from 'app/services/permissions/booking-service-category-permissions.enum';
import { BookingServicePermission } from 'app/services/permissions/booking-service-permissions.enum';
import { BookingsPermission } from 'app/services/permissions/bookings-permissions.enum';
import { BusinessDetailsPermission } from 'app/services/permissions/business-details-permissions.enum';
import { CalendarPermission } from 'app/services/permissions/calendar-permissions.enum';
import { ManageResourcesPermission } from 'app/services/permissions/manage-resources-permissions.enum';
import { RegionPageSettingsPermission } from 'app/services/permissions/region-page-settings-permission.enum';
import { WalletPermission } from 'app/services/permissions/wallet-permission.enum';

export const PERMISSIONS_MOCK: string[] = [
  // Account
  AccountPermission.VIEW_USER_PROFILE,
  AccountPermission.VIEW_PARTNER_PROFILE,
  AccountPermission.EDIT_PARTNER_PROFILE,
  // UserMangement
  UserPermission.LIST,
  UserPermission.EXPORT,
  UserPermission.DETAILS,
  UserPermission.INVITE,
  UserPermission.DELETE,
  UserPermission.INACTIVATE,
  UserPermission.LOGIN,
  UserPermission.RESEND_INVITATION_MAIL,
  UserPermission.ADD_ROLES,
  // Partner
  PartnerPermission.ACCEPT_STEM4_GIFTCARD,
  PartnerPermission.CREATE_REGION_SUB_PAGE,
  PartnerPermission.CREATE_SMART_CITY_PARTNER,
  PartnerPermission.CREATE,
  PartnerPermission.DETAILS,
  PartnerPermission.EDIT_PARTNER_GENERAL_SETTINGS,
  PartnerPermission.EDIT_PARTNER_INVOICING_SETTINGS,
  PartnerPermission.EDIT_PARTNER_SALES_SETTINGS,
  PartnerPermission.EDIT_PARTNER_WEBSITE_SETTINGS,
  PartnerPermission.EDIT,
  PartnerPermission.LIST,
  PartnerPermission.PARTNER_GET_ME,
  PartnerPermission.PARTNER_UPDATE_ME,
  // System Roles
  SystemRolePermission.LIST,
  SystemRolePermission.DETAILS,
  SystemRolePermission.CREATE,
  SystemRolePermission.EDIT,
  SystemRolePermission.DELETE,
  // Custom Roles
  CustomRolePermission.LIST,
  CustomRolePermission.DETAILS,
  CustomRolePermission.CREATE,
  CustomRolePermission.EDIT,
  CustomRolePermission.DELETE,
  // Contracts
  ContractsPermission.LIST,
  ContractsPermission.CREATE,
  ContractsPermission.EDIT,
  ContractsPermission.DELETE,
  // Events V2
  EventPermission.ADD_NEW_EVENT_TO_PROGRAM,
  EventPermission.ADD_RECURRING,
  EventPermission.ADD_TICKET,
  EventPermission.ADD_TICKET_TEMPLATE,
  EventPermission.ASSIGN_CUSTOM_FIELDS,
  EventPermission.CHANGE_TICKET_TEMPLATE_OF_EVENT,
  EventPermission.COPY_TICKETS_FROM_EXISTING_EVENTS,
  EventPermission.CREATE,
  EventPermission.CREATE_EVENT,
  EventPermission.CREATE_LIVE_STREAM_EVENT,
  EventPermission.CREATE_LIVE_STREAM_VIDEO_EVENT,
  EventPermission.CREATE_NO_DATE_EVENT,
  EventPermission.CREATE_TICKET_GROUP,
  EventPermission.CREATE_TIME_SLOT,
  EventPermission.CREATE_VIDEO_EVENT,
  EventPermission.DELETE_AND_RESTORE_PROGRAM,
  EventPermission.DELETE_EVENT,
  EventPermission.DOWNLOAD_QR_CODE,
  EventPermission.EDIT_TIMESLOT,
  EventPermission.EVENT_EDIT_BASIC_INFO,
  EventPermission.EVENT_EDIT_TICKET_OF_EVENT,
  EventPermission.GUEST_LIST_V2,
  EventPermission.INHERIT_PROGRAM_MODIFICATIONS,
  EventPermission.LIST_EVENTS,
  EventPermission.LIST_PROGRAMS,
  EventPermission.MANAGE_VIDEO,
  EventPermission.NTAK_OBLIGED_EVENT,
  EventPermission.NTAK_OBLIGED_PROGRAM,
  EventPermission.NTAK_VIEW_SETTINGS,
  EventPermission.ORGANIZE_TICKETS,
  EventPermission.PROGRAM_EDIT_BASIC_INFO,
  EventPermission.REACH_PROGRAM_PAGE,
  EventPermission.SAVE_PROGRAM_AND_EVENT_AS_DRAFT,
  EventPermission.SET_TICKET_TEMPLATE_OF_TICKET,
  EventPermission.SET_TICKET_VALIDITY_FOR_ONLINE_EVENTS,
  EventPermission.VIEW_CUSTOM_FIELDS,
  EventPermission.VIEW_EVENT,
  EventPermission.VIEW_PROGRAM,
  EventPermission.WITHOUT_TICKET,
  // Discount
  DiscountPermission.LIST,
  DiscountPermission.CREATE,
  DiscountPermission.EDIT,
  DiscountPermission.UPLOAD,
  DiscountPermission.DOWNLOAD,
  DiscountPermission.VIEW,
  // Custom fields
  CustomFieldsPermission.LIST,
  CustomFieldsPermission.CREATE_CHECKBOX,
  CustomFieldsPermission.CREATE_DROPDOWN,
  CustomFieldsPermission.CREATE_FREE_TEXT,
  CustomFieldsPermission.DELETE_AND_RESTORE_CUSTOM_FIELD,
  CustomFieldsPermission.EDIT,
  // Places
  PlacePermission.CREATE_AND_EDIT_AUDITORIUM_LAYOUT,
  PlacePermission.CREATE_NEW_PLACE,
  PlacePermission.CREATE_NON_SEATED_AUDITORIUM,
  PlacePermission.CREATE_PRICING_MAP,
  PlacePermission.CREATE_SEATED_AUDITORIUM,
  PlacePermission.DELETE_AND_RESTORE_AUDITORIUM,
  PlacePermission.DELETE_AND_RESTORE_AUDITORIUM_LAYOUT,
  PlacePermission.DELETE_AND_RESTORE_PLACE,
  PlacePermission.DELETE_AND_RESTORE_PRICING_MAP,
  PlacePermission.EDIT_AUDITORIUM,
  PlacePermission.EDIT_PLACE,
  PlacePermission.LIST_AND_SEARCH,
  PlacePermission.VIEW_AUDITORIUM,
  PlacePermission.VIEW_PLACE,
  // Categories
  CategoryPermission.LIST,
  CategoryPermission.DETAILS,
  CategoryPermission.CREATE,
  CategoryPermission.EDIT,
  CategoryPermission.DELETE,
  // Card template
  CardTemplatePermission.COPY,
  CardTemplatePermission.DETAILS,
  CardTemplatePermission.CREATE_MASTER,
  CardTemplatePermission.EDIT_MASTER,
  CardTemplatePermission.CREATE_CUSTOM,
  CardTemplatePermission.EDIT_CUSTOM,
  CardTemplatePermission.PRINT,
  CardTemplatePermission.LIST,
  CardTemplatePermission.DELETE_AND_RESTORE,
  // Ticket template
  TicketTemplatePermission.COPY,
  TicketTemplatePermission.DETAILS,
  TicketTemplatePermission.CREATE_MASTER,
  TicketTemplatePermission.EDIT_MASTER,
  TicketTemplatePermission.CREATE_CUSTOM,
  TicketTemplatePermission.EDIT_CUSTOM,
  TicketTemplatePermission.PRINT,
  TicketTemplatePermission.LIST,
  TicketTemplatePermission.DELETE_AND_RESTORE,
  // Rate
  RatePermission.FEE_LIST,
  RatePermission.FEE_DELETE,
  RatePermission.FEE_CREATE,
  RatePermission.FEE_VIEW,
  RatePermission.FEE_EDIT,
  RatePermission.DISCOUNT_LIST,
  RatePermission.DISCOUNT_DELETE,
  RatePermission.DISCOUNT_CREATE,
  RatePermission.DISCOUNT_VIEW,
  RatePermission.DISCOUNT_EDIT,
  // Refund
  RefundPermission.TICKETS,
  RefundPermission.STEM5_TICKETS,
  RefundPermission.PASSED_TICKETS,
  // Boxoffice
  BoSalesPermission.LIST_AND_SEARCH_EVENTS,
  BoSalesPermission.SELL_AND_CHECKOUT,
  BoSalesPermission.SELL_AND_CHECK_IN,
  BoSalesPermission.SELL_AND_PRINT,
  BoSalesPermission.SELL_TICKET,
  // BoSalesPermission.SELL_WITH_SHOPBOX,
  BoSalesPermission.SELL_GIFT_CARDS,
  BoSalesPermission.REDEEM_GIFT_CARDS,
  BoSalesPermission.SELL_DISCOUNT_CARDS,
  // BoSalesPermission.REDEEM_DISCOUNT_CARDS,
  BoSalesPermission.SELL_CUSTOM_CARDS,
  // BoSalesPermission.REDEEM_CUSTOM_CARDS,
  // Transactions
  TransactionPermission.LIST,
  TransactionPermission.DETAILS,
  TransactionPermission.DOWNLOAD_TICKETS_PDF,
  TransactionPermission.RESEND_TICKETS,
  TransactionPermission.RETRY_SALES_TRANSACTION,
  TransactionPermission.RETRY_REFUND_TRANSACTION,
  TransactionPermission.EXPORT_TO_EXCEL,
  TransactionPermission.REGENERATE_TRANSACTION_ITEMS,
  // Report
  ReportPermission.LIST,
  ReportPermission.EXPORT_TO_EXCEL,
  // Reporting
  ReportingPermission.USE_ADMISSION_REPORT,
  ReportingPermission.ADMISSION_REPORT_EXPORT_TO_EXCEL,
  ReportingPermission.USE_GIFT_CARD_REPORT,
  ReportingPermission.USE_GIFT_CARD_EXPORT_TO_EXCEL,
  ReportingPermission.FINANCIAL_REPORT_LIST,
  ReportingPermission.FINANCIAL_REPORT_EXPORT_TO_CSV,
  // OTRS
  OTRSManagementPermission.LIST,
  OTRSManagementPermission.CREATE,
  OTRSManagementPermission.APPROVE,
  OTRSManagementPermission.DEACTIVATE,
  OTRSManagementPermission.VERSION_CREATE,
  // Region
  RegionsPermission.LIST,
  RegionsPermission.DETAILS,
  RegionsPermission.CREATE,
  RegionsPermission.EDIT,
  // Dashboard
  DashboardPermission.PROFIT,
  DashboardPermission.REVENUE,
  DashboardPermission.SOLD_QUANTITY,
  DashboardPermission.UPCOMING_EVENTS,
  DashboardPermission.LATEST_TRANSACTIONS,
  DashboardPermission.MANAGE_FILTERS,
  // Admission
  AdmissionPermission.LIST,
  AdmissionPermission.CHECK_IN,
  AdmissionPermission.CHECK_OUT,
  // Survey
  SurveyPermission.SURVEY_ANSWER,
  SurveyPermission.SURVEY_CLOSE,
  SurveyPermission.SURVEY_CREATE_DRAFT,
  SurveyPermission.SURVEY_DETAILS,
  SurveyPermission.DUPLICATE,
  SurveyPermission.SURVEY_EXPORT,
  SurveyPermission.SURVEY_LIST,
  SurveyPermission.SURVEY_LIST_PUBLISHED,
  SurveyPermission.SURVEY_PUBLISH,
  SurveyPermission.SURVEY_STATISTICS,
  SurveyPermission.SURVEY_UPDATE_DRAFT,
  SurveyPermission.ADD_TAGS,
  // Attachments
  AttachmentsPermission.UPLOAD,
  // Payment Methods
  PaymentMethodsPermission.MANAGE,
  // Invoice
  InvoicePermission.LIST,
  // Comfort cost
  ComfortCostPermission.LIST,
  ComfortCostPermission.CREATE,
  // Partner Page Settings
  PartnerPageSettingsPermission.EDIT_LANDING,
  PartnerPageSettingsPermission.EDIT_LANDING_SPECIALS,
  // Region Page Settings
  RegionPageSettingsPermission.EDIT_LANDING_REGION_PAGE_BASIC,
  // Card Management
  CardPermission.GIFT_CARD_CREATE,
  CardPermission.GIFT_CARD_LIST,
  CardPermission.GIFT_CARD_VIEW,
  CardPermission.GIFT_CARD_EDIT,
  CardPermission.GIFT_CARD_DELETE,
  CardPermission.ISSUED_GIFT_CARD_LIST,
  CardPermission.ISSUED_GIFT_CARD_EDIT,
  CardPermission.DISCOUNT_CARD_CREATE,
  CardPermission.DISCOUNT_CARD_LIST,
  CardPermission.DISCOUNT_CARD_VIEW,
  // CardPermission.DISCOUNT_CARD_EDIT,
  CardPermission.DISCOUNT_CARD_DELETE,
  CardPermission.ISSUED_DISCOUNT_CARD_LIST,
  // CardPermission.ISSUED_DISCOUNT_CARD_EDIT,
  CardPermission.CUSTOM_CARD_CREATE,
  CardPermission.CUSTOM_CARD_LIST,
  // CardPermission.CUSTOM_CARD_VIEW,
  // CardPermission.CUSTOM_CARD_EDIT,
  CardPermission.CUSTOM_CARD_DELETE,
  // CardPermission.ISSUED_CUSTOM_CARD_LIST,
  // CardPermission.ISSUED_CUSTOM_CARD_EDIT,
  CardPermission.TEMPLATE_BLOCKCHAIN,
  CardPermission.PERMIT_CARD_TEMPLATE_CREATE,
  CardPermission.PERMIT_CARD_TEMPLATE_EDIT,
  CardPermission.PERMIT_CARD_TEMPLATE_VIEW,
  CardPermission.PERMIT_CARD_TEMPLATE_DELETE,
  CardPermission.PERMIT_CARD_REQUEST_LIST,
  CardPermission.PERMIT_CARD_REQUEST_EDIT,
  // Payment Transactions
  PaymentTransactionPermission.LIST,
  // SmartCity BankCard
  BankCardPermission.BANK_CARD_LIST,
  BankCardPermission.BANK_CARD_DETAILS,
  // SmartCity BannerPermission
  BannerPermission.LIST,
  BannerPermission.CREATE,
  BannerPermission.UPDATE,
  BannerPermission.DELETE,
  // SmartCity CardPermission
  SmartCityCardPermission.ADMIN_EDIT,
  SmartCityCardPermission.APPROVE,
  SmartCityCardPermission.CREATE,
  SmartCityCardPermission.DETAILS,
  SmartCityCardPermission.EXPORT,
  SmartCityCardPermission.IMPORT,
  SmartCityCardPermission.LIST,
  SmartCityCardPermission.NUMBER,
  SmartCityCardPermission.WIFI,
  SmartCityCardPermission.CITIZEN_REGISTRY_IMPORT,
  SmartCityCardPermission.APPLY_CITIZEN_CORRECTION,
  SmartCityCardPermission.APPLY_CITIZEN_CORRECTIONS,
  SmartCityCardPermission.REVOKE_STATUSES,
  // SmartCity WalletPermission
  WalletPermission.CARD_SEARCH,
  WalletPermission.CREATE,
  WalletPermission.DELETE,
  WalletPermission.DETAILS,
  WalletPermission.LIST,
  WalletPermission.PDF,
  WalletPermission.STATISTICS,
  WalletPermission.STATUSES,
  WalletPermission.TYPES,
  WalletPermission.UPDATE_STATUS,
  // SmartCity GovernmentPermission
  GovernmentPermission.CREATE,
  GovernmentPermission.DELETE,
  GovernmentPermission.DETAILS,
  GovernmentPermission.LIST,
  GovernmentPermission.STATUSES,
  GovernmentPermission.UPDATE,
  // SmartCity MarketPermission
  MarketPermission.DELETE,
  MarketPermission.LIST,
  MarketPermission.VIEW,
  // SmartCity NotificationsPermission
  NotificationsPermission.LIST_ADMIN_NOTIFICATIONS,
  NotificationsPermission.SEND_PUSH_NOTIFICATION,
  NotificationsPermission.DELETE_NOTIFICATION,
  NotificationsPermission.UPDATE_NOTIFICATION,
  NotificationsPermission.GET_NOTIFICATION,
  // SmartCity PlacePermission
  SmartCityPlacePermission.PLACE_CREATE_DRAFT,
  SmartCityPlacePermission.PLACE_CREATE_PUBLISHED,
  SmartCityPlacePermission.PLACE_CREATE_RATING,
  SmartCityPlacePermission.PLACE_DELETE,
  SmartCityPlacePermission.PLACE_DELETE_COLLECTION,
  SmartCityPlacePermission.PLACE_DETAILS,
  SmartCityPlacePermission.PLACE_LIST,
  SmartCityPlacePermission.PLACE_READ_TYPE,
  SmartCityPlacePermission.PLACE_UPDATE_DRAFT,
  SmartCityPlacePermission.PLACE_UPDATE_PUBLISHED,
  SmartCityPlacePermission.PLACE_UPDATE_RATING,
  // SmartCity ReportPermission
  SmartCityReportPermission.REPORT_DETAILS,
  SmartCityReportPermission.REPORT_EXPORT,
  SmartCityReportPermission.REPORT_LIST,
  SmartCityReportPermission.REPORT_RATING_OWN,
  SmartCityReportPermission.REPORT_UPDATE,
  // ArticleSettingsPermission
  ArticleSettingsPermission.ARTICLE_SETTINGS_DETAILS,
  ArticleSettingsPermission.ARTICLE_SETTINGS_UPDATE,
  // ContentManagementPermission
  ContentManagementPermission.ARCHIVE,
  ContentManagementPermission.CREATE,
  ContentManagementPermission.LIST,
  ContentManagementPermission.PUBLISH,
  ContentManagementPermission.UPDATE,
  // ContentTranslationPermission
  ContentTranslationPermission.USE,
  // ReportCategoryPermissions
  ReportCategoryPermissions.REPORT_CATEGORY_ADMIN_LIST,
  ReportCategoryPermissions.REPORT_CATEGORY_CREATE,
  ReportCategoryPermissions.REPORT_CATEGORY_DELETE,
  ReportCategoryPermissions.REPORT_CATEGORY_UPDATE,
  ReportCategoryPermissions.REPORT_CATEGORY_UPDATE_ORDER,
  // AreaOfInterestPermission
  AreaOfInterestPermission.LIST,
  // Place
  PlaceCategoriesPermissions.LIST,
  PlaceCategoriesPermissions.CREATE,
  PlaceCategoriesPermissions.UPDATE,
  PlaceCategoriesPermissions.DELETE,
  // ParkingPermission
  ParkingPermission.LIST,
  // VignettePermission
  VignettePermission.LIST,
  // StepToStopPermission
  StepToStopPermission.LIST,
  StepToStopPermission.EXPORT,
  // ProductPermission
  ProductPermission.LIST,
  // TranslationsManagementPermission
  TranslationsManagementPermission.LIST,
  TranslationsManagementPermission.CREATE,
  TranslationsManagementPermission.UPDATE,
  TranslationsManagementPermission.DELETE,
  TranslationsManagementPermission.CREATE_TAG,
  TranslationsManagementPermission.CREATE_LANGUAGE,
  TranslationsManagementPermission.LIST_LANGUAGES,
  TranslationsManagementPermission.UPDATE_LANGUAGE,
  TranslationsManagementPermission.AUTO_TRANSLATE_MANY,
  TranslationsManagementPermission.AUTO_TRANSLATE_BY_ID,
  TranslationsManagementPermission.EXPORT,
  TranslationsManagementPermission.IMPORT,
  TranslationsManagementPermission.IMPORT_PLATFORM,
  TranslationsManagementPermission.DISPLAY_LANGUAGE_LABELS,
  // PartnersApiManagementPermission
  PartnersApiManagementPermission.MANAGE,
  PartnersApiManagementPermission.INTEGRATION,
  // OrganizerPermission
  OrganizerPermission.EDIT_CART_ITEM_HOLDER_SETTINGS,
  OrganizerPermission.EDIT_DEFAULT_SETTINGS,
  OrganizerPermission.EDIT_NTAK_SETTINGS,
  OrganizerPermission.EDIT_SALES_SETTINGS,
  OrganizerPermission.EDIT_FACEBOOK_TRACKING_SETTINGS,
  OrganizerPermission.EDIT_GOOGLE_TRACKING_SETTINGS,
  OrganizerPermission.EDIT_CHECKOUT_SURVEY_DETAILS,
  OrganizerPermission.EDIT_WEBSITE_SETTINGS,
  // DataPumpPermission
  DataPumpPipelinePermission.LIST,
  DataPumpPipelinePermission.LIST_TEMPLATES,
  DataPumpPipelinePermission.VIEW,
  DataPumpPipelinePermission.CREATE,
  DataPumpPipelinePermission.EDIT,
  DataPumpPipelinePermission.COPY,
  DataPumpPipelinePermission.DELETE,
  DataPumpPipelinePermission.RE_TRIGGER,
  DataPumpPipelinePermission.VIEW_EXECUTIONS,
  DataPumpPipelinePermission.VIEW_EXECUTIONS_LOGS,
  DataPumpPipelinePermission.START,
  // EmailTemplatesPermission
  EmailTemplatesPermission.LIST,
  EmailTemplatesPermission.DELETE,
  EmailTemplatesPermission.DETAILS,
  EmailTemplatesPermission.EDIT_MASTER,
  EmailTemplatesPermission.EDIT_CUSTOM,
  EmailTemplatesPermission.COPY,
  EmailTemplatesPermission.SEND,
  EmailTemplatesPermission.CREATE_MASTER,
  EmailTemplatesPermission.CREATE_CUSTOM,
  EmailTemplatesPermission.RESTORE,
  // EmailTypesPermission
  EmailTypesPermission.LIST,
  EmailTypesPermission.CREATE,
  EmailTypesPermission.EDIT,
  EmailTypesPermission.VIEW,
  EmailTypesPermission.DELETE,
  // EmailComponentsPermission
  EmailComponentsPermission.LIST,
  EmailComponentsPermission.VIEW,
  EmailComponentsPermission.CREATE,
  EmailComponentsPermission.EDIT,
  EmailComponentsPermission.COPY,
  EmailComponentsPermission.DELETE,
  EmailComponentsPermission.RESTORE,
  // HtmlBuilderPermission
  HtmlBuilderPermission.LOCK_OR_UNLOCK,
  // Development work in progress
  WorkInProgressPermission.BETA_TESTER,
  // Booking
  // BusinessDetails
  BusinessDetailsPermission.BUSINESS_DETAILS_UPDATE,
  BusinessDetailsPermission.BUSINESS_DETAILS_VIEW,
  // Resources
  ManageResourcesPermission.RESOURCES_LIST,
  ManageResourcesPermission.RESOURCES_GET,
  ManageResourcesPermission.RESOURCES_EDIT,
  ManageResourcesPermission.RESOURCES_CREATE,
  ManageResourcesPermission.RESOURCES_DELETE,
  // ServiceCategories
  BookingServiceCategoryPermissions.SERVICE_CATEGORIES_LIST,
  BookingServiceCategoryPermissions.GET_SERVICE_CATEGORY,
  BookingServiceCategoryPermissions.EDIT_SERVICE_CATEGORY,
  BookingServiceCategoryPermissions.DELETE_SERVICE_CATEGORY,
  BookingServiceCategoryPermissions.CREATE_SERVICE_CATEGORY,
  // Services
  BookingServicePermission.LIST_SERVICE,
  BookingServicePermission.GET_SERVICE,
  BookingServicePermission.CREATE_SERVICE,
  BookingServicePermission.DELETE_SERVICE,
  BookingServicePermission.EDIT_SERVICE,
  // Bookings
  BookingsPermission.BOOKINGS_LIST,
  BookingsPermission.BOOKINGS_GET_ONE,
  BookingsPermission.BOOKINGS_EDIT,
  BookingsPermission.BOOKINGS_SETTINGS,
  // Calendar view
  CalendarPermission.CALENDAR_GET_BOOKINGS,
  CalendarPermission.CALENDAR_GET_MY_BOOKINGS,
  CalendarPermission.CALENDAR_CREATE_BOOKING,
  CalendarPermission.CALENDAR_CREATE_WORKBREAK,
  CalendarPermission.CALENDAR_UPDATE_WORKBREAK,
  CalendarPermission.CALENDAR_DELETE_WORKBREAK,
  // Blockchain
  BlockchainPermission.BLOCKCHAIN_CONFIG_CREATE,
  BlockchainPermission.BLOCKCHAIN_CONFIG_DELETE,
  BlockchainPermission.BLOCKCHAIN_CONFIG_EDIT,
  BlockchainPermission.BLOCKCHAIN_CONFIG_LIST,
  BlockchainPermission.BLOCKCHAIN_CONFIG_VIEW,
  // Smart Contract
  BlockchainPermission.SMART_CONTRACT_LIST,
  BlockchainPermission.SMART_CONTRACT_CREATE,
  BlockchainPermission.SMART_CONTRACT_DELETE,
  BlockchainPermission.SMART_CONTRACT_VIEW,
  BlockchainPermission.SMART_CONTRACT_EDIT,
  BlockchainPermission.SMART_CONTRACT_DEPLOY_ERC20,
  BlockchainPermission.SMART_CONTRACT_DEPLOY_ERC721,
  // Service
  BlockchainPermission.SERVICE_CREATE,
  BlockchainPermission.SERVICE_LIST,
  BlockchainPermission.SERVICE_VIEW,
  BlockchainPermission.SERVICE_EDIT,
  BlockchainPermission.SERVICE_DELETE,
  // Text Feedback
  TextFeedbackPermission.TEMPLATE_CREATE,
  TextFeedbackPermission.TEMPLATE_COPY,
  TextFeedbackPermission.TEMPLATE_VIEW,
  TextFeedbackPermission.TEMPLATE_EDIT,
  TextFeedbackPermission.TEMPLATE_DELETE,
  TextFeedbackPermission.INSTANCE_VIEW,
  TextFeedbackPermission.INSTANCE_EDIT,
  TextFeedbackPermission.INSTANCE_DELETE,
  TextFeedbackPermission.INSTANCE_DOWNLOAD,
];
