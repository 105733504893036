export const NotepadFile = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.875 3.75H4.75C4.19772 3.75 3.75 4.19772 3.75 4.75V17.125C3.75 17.6773 4.19772 18.125 4.75 18.125H15.25C15.8023 18.125 16.25 17.6773 16.25 17.125V4.75C16.25 4.19772 15.8023 3.75 15.25 3.75H13.125" stroke="currentColor" stroke-width="1.5"/>
  <path d="M13.125 5.625H6.875V2.875C6.875 2.32272 7.32272 1.875 7.875 1.875H12.125C12.6773 1.875 13.125 2.32272 13.125 2.875V5.625Z" stroke="currentColor" stroke-width="1.5"/>
  <path d="M8.75 8.75L13.75 8.75" stroke="currentColor" stroke-width="1.5"/>
  <path d="M6.25 8.75L7.5 8.75" stroke="currentColor" stroke-width="1.5"/>
  <path d="M6.25 11.875L7.5 11.875" stroke="currentColor" stroke-width="1.5"/>
  <path d="M6.25 15L7.5 15" stroke="currentColor" stroke-width="1.5"/>
  <path d="M8.75 11.875L13.75 11.875" stroke="currentColor" stroke-width="1.5"/>
  <path d="M8.75 15L13.75 15" stroke="currentColor" stroke-width="1.5"/>
</svg>`;
