import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiKitPalette } from '@ui-kit/enums';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IUiKitConfirmModalData } from '../../interfaces/ui-kit-confirm-modal-data.interface';
import { UiKitModalComponent } from '../ui-kit-modal/ui-kit-modal.component';

type palettes = Partial<Record<UiKitPalette, {cancelButtonPalette: UiKitPalette; isLightCancelButton: boolean}>>;

@Component({
  selector: 'ui-kit-confirm-modal',
  templateUrl: './ui-kit-confirm-modal.component.html',
  styleUrls: ['./ui-kit-confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitConfirmModalComponent extends UiKitModalComponent implements OnDestroy {

  private readonly cancelButtonPalettes: palettes = {
    [UiKitPalette.PRIMARY]: {
      cancelButtonPalette: UiKitPalette.SECONDARY,
      isLightCancelButton: false,
    },
    [UiKitPalette.ORANGE]: {
      cancelButtonPalette: UiKitPalette.ORANGE,
      isLightCancelButton: true,
    },
    [UiKitPalette.PURPLE]: {
      cancelButtonPalette: UiKitPalette.SECONDARY,
      isLightCancelButton: false,
    },
    [UiKitPalette.PINK]: {
      cancelButtonPalette: UiKitPalette.PINK,
      isLightCancelButton: true,
    },
  };

  constructor(
    private dialogRef: MatDialogRef<UiKitConfirmModalComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private modalData: IUiKitConfirmModalData,
  ) {
    super();
    dialogRef.addPanelClass('ui-kit-modal-panel');
  }

  ngOnDestroy(): void {
    this.modalData?.confirmed$?.complete();
    this.modalData?.confirmClicked$?.complete();
    this.modalData?.isLoading$?.complete();
  }

  get confirmTitle(): string {
    return this.modalData?.confirmTitle || this.modalData?.message || '';
  }

  get message(): string {
    return this.modalData?.confirmTitle ? (this.modalData?.message || '') : '';
  }

  get confirmButtonLabel(): string {
    return this.modalData?.confirmButtonLabel || 'general_lang_confirm';
  }

  get dismissButtonLabel(): string {
    return this.modalData?.dismissButtonLabel || 'general_lang_cancel';
  }

  get infoOnlyMode(): boolean {
    return !!this.modalData?.infoOnlyMode;
  }

  get isDefinedPalette(): boolean {
    return !!this.modalData?.modalPalette && !!this.cancelButtonPalettes[this.modalData?.modalPalette];
  }

  get isLightCancelButton(): boolean {
    return this.isDefinedPalette ?
      this.cancelButtonPalettes[this.modalData.modalPalette].isLightCancelButton :
      !!this.modalData?.modalPalette;
  }

  get cancelButtonPalette(): UiKitPalette {
    return this.isDefinedPalette ?
      this.cancelButtonPalettes[this.modalData.modalPalette].cancelButtonPalette :
      (this.modalData?.modalPalette || UiKitPalette.SECONDARY);
  }

  get confirmButtonPalette(): UiKitPalette {
    return this.modalData?.modalPalette || UiKitPalette.PRIMARY;
  }

  get isLoading$(): Observable<boolean> {
    const stream$ = this.modalData?.isLoading$ || of(false);

    return stream$.pipe(
      tap((isLoading: boolean) => this.dialogRef.disableClose = this.disableCloseWhileLoading && isLoading)
    );
  }

  get isConfirmButtonDisabled$(): Observable<boolean> {
    return this.modalData?.isConfirmButtonDisabled$ || of(false);
  }

  get disableCloseWhileLoading(): boolean {
    return !!this.modalData?.disableCloseWhileLoading;
  }

  get fullWidth(): boolean {
    return this.modalData?.fullWidth;
  }

  updateModalData(data: Partial<IUiKitConfirmModalData>): void {
    const { message, confirmButtonLabel, dismissButtonLabel, infoOnlyMode } = data;

    this.modalData.isLoading$ = new BehaviorSubject<boolean>(false);
    this.modalData.confirmButtonLabel = confirmButtonLabel || this.confirmButtonLabel;
    this.modalData.dismissButtonLabel = dismissButtonLabel || this.dismissButtonLabel;
    this.modalData.message = message || this.message;
    this.modalData.infoOnlyMode = infoOnlyMode ?? this.infoOnlyMode;

    this.cdr.markForCheck();
  }

  onDismiss(): void {
    this.modalData.confirmed$?.next(false);
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    // async closing process, closing triggered from calling method
    this.modalData?.confirmClicked$?.next(this.modalData);
    this.modalData?.confirmed$?.next(true);

    // closes immediately
    if (!this.modalData?.confirmClicked$ && !this.modalData?.confirmed$) {
      this.dialogRef.close(true);
    }
  }

}
