import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, input, signal, viewChild } from '@angular/core';

@Component({
  selector: 'ui-kit-show-more',
  templateUrl: './ui-kit-show-more.component.html',
  styleUrls: ['./ui-kit-show-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiKitShowMoreComponent {

  readonly text = input<string>();
  readonly paragraph = input(true);
  readonly shouldConstrainFullText = input(false);
  readonly hasMargin = input(true);
  readonly fullTextConstraint = input(255);

  private readonly descriptionText = viewChild<ElementRef<HTMLElement>>('descriptionText');

  readonly showMoreLinkVisible = signal(false);
  readonly showMore = signal(false);

  readonly isConstrained = computed(() => this.shouldConstrainFullText() && this.text().length > this.fullTextConstraint());
  readonly visibleText = computed(() => this.isConstrained() ? `${this.text().substring(0, this.fullTextConstraint())}...` : this.text());

  constructor() {
    effect(() => {
      const isConstrained = this.isConstrained();
      const descriptionText = this.descriptionText();

      setTimeout(() => {
        this.showMoreLinkVisible.set(descriptionText?.nativeElement.scrollHeight > descriptionText?.nativeElement.offsetHeight && isConstrained);
      });
    }, { allowSignalWrites: true });
  }

  onToggleShowMore(): void {
    this.showMore.update((prev) => !prev);
  }

}
