<div class="description-container" [class.has-margin]="hasMargin()" [class.expanded]="showMore()">
  @if (paragraph()) {
    <p #descriptionText class="description-text">{{ visibleText() }}</p>
  } @else {
    <div #descriptionText class="description-text">{{ visibleText() }}</div>
  }

  @if (showMoreLinkVisible()) {
    <span (click)="onToggleShowMore()" class="toggle-button">
      {{ (showMore() ? 'show_less' : 'show_more') | translate }}
    </span>
  }
</div>
