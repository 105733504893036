<ui-kit-modal
  [title]="'title'"
  [class.full-width]="fullWidth"
  [internalScrollContainer]="internalScrollContainer">
  <div>
    <div class="confirm-title">
      {{ confirmTitle | translate }}
    </div>

    <div *ngIf="message" class="confirm-message">
      {{ message | translate }}
    </div>
  </div>

  <div [class.content-internal-scroll]="internalScrollContainer">
    <ng-content />
  </div>

  <ng-template #actions>
    <ui-kit-button
      *ngIf="!infoOnlyMode"
      [label]="dismissButtonLabel"
      [isDisabled]="disableCloseWhileLoading && (isLoading$ | async)"
      (onClick)="onDismiss()"
      [palette]="cancelButtonPalette"
      [isLight]="isLightCancelButton">
    </ui-kit-button>

    <ui-kit-button
      (onClick)="onConfirm()"
      [label]="confirmButtonLabel"
      [icon]="'check'"
      [isDisabled]="(isLoading$ | async) || (isConfirmButtonDisabled$ | async)"
      [isLoading]="isLoading$ | async"
      [palette]="confirmButtonPalette">
    </ui-kit-button>
  </ng-template>
</ui-kit-modal>
